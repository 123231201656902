export enum ItemType {
  Armor = 'armor',
  Weapon = 'weapon',
  Potion = 'potion',
  Food = 'food',
  Consumable = 'consumable',
  Ressource = 'ressource',
  Miscellaneous = 'miscellaneous',
  Book = 'book',
  Recipe = 'recipe',
  Quest = 'quest',
}
