import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HudDisplayState } from '@t12/common/overlay/interfaces/hud-display-state.interface';

export const HudDisplayActions = createActionGroup({
  source: 'HUD',
  events: {
    Reset: emptyProps(),
    'Close all hud': emptyProps(),
    'Show hud': props<{ name: keyof HudDisplayState }>(),
    'Hide hud': props<{ name: keyof HudDisplayState }>(),
    'Toggle hud': props<{ name: keyof HudDisplayState }>(),

    'Handle display': props<{ display: boolean; hud: keyof HudDisplayState }>(),
    'Handle display inventory': props<{ display: boolean }>(),
    'Handle display container': props<{ display: boolean }>(),
    'Handle display player infos': props<{ display: boolean }>(),
    'Handle display jobs': props<{ display: boolean }>(),
    'Handle display quests': props<{ display: boolean }>(),
    'Handle display chat': props<{ display: boolean }>(),
  },
});
