import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  EventWeather,
  EventDialog,
  EventCharacter,
  EventPopUp,
} from '@t12/common/events/type/event-step.interface';
import { EventSteps } from '@t12/common/events/type/event-step.type';

export const EventsActions = createActionGroup({
  source: 'EVENT',
  events: {
    'Set event steps': props<{ eventSteps: EventSteps }>(),

    'Start event': emptyProps(),
    'Start event weather': props<{ event: EventWeather }>(),
    'Start event dialog': props<{ event: EventDialog }>(),
    'Start event dialog failed': emptyProps(),
    'Start event character': props<{ event: EventCharacter }>(),
    'Start event popup': props<{ event: EventPopUp }>(),

    'Next event': emptyProps(),

    'End event': emptyProps(),
  },
});
