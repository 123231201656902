import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { isNPC } from '@t12/characters/constants/is-npc.constant';
import { CharactersMoveActions } from '@t12/characters/store/actions/move/characters-move.actions';
import {
  getCharacterInFrontOfPlayer,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterState } from '@t12/common/characters/enums/character-state.enum';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { OptionKind } from '@t12/common/dialog/enums/option-kind.enum';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { EventsActions } from '@t12/events/store/actions/events.actions';
import { getEventsProgressStatus } from '@t12/events/store/selectors/events.selectors';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display/hud-display.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { areQuestGoalsDone } from '@t12/quest/store/selectors/quest.selectors';
import { ShopActions } from '@t12/shop/store/actions/shop/shop.actions';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import {
  catchError,
  withLatestFrom,
  switchMap,
  map,
  take,
  filter,
  from,
  of,
} from 'rxjs';
import { DialogActions } from '../actions/dialog.actions';
import {
  getActiveTextDialog,
  isLastDialogText,
  getDialogCharacter,
  getDialogOptions,
} from '../selectors/dialog.selectors';

@Injectable()
export class DialogEffects {
  private _startConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversation),
      withLatestFrom(
        this._store.select(getCharacterInFrontOfPlayer),
        this._store.select(getPlayerID),
      ),
      filter(
        ([{ npc }, character]) => isNPC(character) && npc.id === character.id,
      ),
      switchMap(([_, character, playerId]) => {
        return this._dialogDb
          .getDialog(playerId, (character as INPC).dialogCode)
          .pipe(
            take(1),
            filter((textsDialog) => textsDialog.length > 0),
            switchMap((textsDialog) => [
              CharactersMoveActions.setFaceToPlayer({
                id: character.id,
                kind: character.kind,
              }),
              DialogActions.startConversationSuccess({
                textsDialog,
                npc: character as INPC,
              }),
            ]),
            catchError(() => of(DialogActions.startConversationFailed())),
          );
      }),
    ),
  );

  private _startConversationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversationSuccess),
      map(() => HudDisplayActions.showHud({ name: 'dialog' })),
    ),
  );

  private _continueConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.continueConversation),
      withLatestFrom(
        this._store.select(getActiveTextDialog),
        this._store.select(isLastDialogText),
      ),
      filter(([_, textDialog]) => !!textDialog),
      map(([_, textDialog, isLastDialogText]) => {
        const option = textDialog.options?.find((option) => !option.disable);
        if (option) return DialogActions.chooseOption({ option });

        return isLastDialogText
          ? DialogActions.endConversation()
          : DialogActions.nextMessage();
      }),
    ),
  );

  private _setOptionsValidations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversationSuccess, DialogActions.nextMessage),
      withLatestFrom(this._store.select(getDialogOptions)),
      filter(([_, options]) => options?.some((option) => option.validateQuest)),
      switchMap(([_, options]) => {
        const optionToValidate = options.find(
          (option) => option.validateQuest,
        )!;

        return this._store
          .select(areQuestGoalsDone(optionToValidate.validateQuest))
          .pipe(
            take(1),
            filter((isDone) => !isDone),
            map(() =>
              DialogActions.setOptionValidateQuestDisable({ disable: true }),
            ),
          );
      }),
    ),
  );

  private _chooseOption$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.chooseOption),
      withLatestFrom(this._store.select(getDialogCharacter)),
      filter(([{ option }]) => !option.disable),
      switchMap(([{ option }, npc]) => {
        switch (option.kind) {
          case OptionKind.SHOP_BUY:
            return [ShopActions.openShop({ npcCode: npc.code })];
          case OptionKind.BANK_OPEN:
            return [BankActions.openBank()];
          default:
            const actions: Action[] = [
              DialogActions.nextMessageOption({ option }),
            ];
            if (option.createQuest && isNPC(npc)) {
              actions.push(
                QuestActions.addQuest({
                  questCode: option.createQuest,
                  npc,
                }),
              );
            } else if (option.validateQuest && isNPC(npc)) {
              actions.push(
                QuestActions.validateQuest({
                  questCode: option.validateQuest,
                }),
              );
            }
            return actions;
        }
      }),
    ),
  );

  private _endConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.endConversation),
      withLatestFrom(
        this._store.select(getDialogCharacter),
        this._store.select(getEventsProgressStatus),
      ),
      switchMap(([_, npc, eventStatus]) => {
        const actions: Array<Action> = [
          HudDisplayActions.hideHud({ name: 'dialog' }),
          DialogActions.endConversationSuccess(),
        ];

        if (
          npc?.state === CharacterState.IDLE &&
          eventStatus !== ProgressStatus.IN_PROGRESS
        ) {
          const { id, kind, originalLooking } = npc;
          actions.push(
            CharactersMoveActions.setLooking({
              id,
              kind,
              looking: originalLooking,
            }),
          );
        }

        if (eventStatus === ProgressStatus.IN_PROGRESS)
          actions.push(EventsActions.nextEvent());

        return from(actions);
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDb: DialogDbService,
    private readonly _store: Store,
  ) {}
}
