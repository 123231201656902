import { createReducer, on } from '@ngrx/store';
import { HudDisplayState } from '@t12/common/overlay/interfaces/hud-display-state.interface';
import { HudDisplayActions } from '../../actions/hud-display/hud-display.actions';
import { initialHudDisplayState } from '../../index';

export const HudDisplayReducer = createReducer(
  initialHudDisplayState,

  on(HudDisplayActions.reset, ({ presentation }: HudDisplayState) => ({
    ...initialHudDisplayState,
    presentation,
  })),

  on(HudDisplayActions.closeAllHud, () => ({
    ...initialHudDisplayState,
    barInfos: true,
  })),

  on(HudDisplayActions.showHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: true,
  })),

  on(HudDisplayActions.hideHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: false,
  })),

  on(HudDisplayActions.toggleHud, (hotkeyState: HudDisplayState, props) => ({
    ...hotkeyState,
    [props.name]: !hotkeyState[props.name],
  })),
);
